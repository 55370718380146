import { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import {getAllNotificationTemplates, deleteNotificationTemplate} from "../../services/api";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../../services/PageLoader";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { KTIcon } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";

const NotificationTemplateList = () => {
    const {currentUser} = useAuth()
    const [templateData,setTemplateData] = useState<Array<Object>>([]);
    const [dataCount, setDataCount] = useState<any>(0)
    const [searchTerm,setSearchTerm] = useState<string>('');
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const noOfItems : number = 9

    const getData = async () => {
        try{
            const response = await getAllNotificationTemplates({page,noOfItems, searchTerm});
            setTemplateData(response?.data?.templatesData);
            setDataCount(response?.data?.count)
            setShowLoader(false)
        }catch(error:any){
            console.log(error?.response?.data?.message)
            setShowLoader(false)
        }
    }

    const handleDelete = async(id) => {
        try{
            setShowLoader(true)
            const resp : any = await deleteNotificationTemplate(id);
            setModelBody(resp?.data?.message);
            setShow(true)
            setShowLoader(false)
            getData()
        }catch(error:any){
            console.log(error?.response?.data?.message)
            setShowLoader(false)
        }
    }

    //search
    // const onSearch = async(e) => {
    //     setSearchTerm(e.target.value);
    //     try{
    //         // setShowLoader(true);
    //         const response = await getEmployees(e.target.value);
    //         setEmployeeData(response?.data)
    //         // setShowLoader(false)

    //     }catch(error){
    //         console.log(error)
    //         // setShowLoader(false)
    //     }
    // }
    useEffect(()=>{
        setShowLoader(true)
        getData();
    },[]);

    useEffect(()=>{
        setShowLoader(true)
        getData();
    },[page])

    const handleSearch = async (e : any) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        const timeOut = setTimeout(()=>{
            getData();
        },1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[searchTerm])

    return(
        <div id="kt_app_content" className="app-content flex-column-fluid">
            <div className='card-title'>
              {/* begin::Search */}
              <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                      type='text'
                      id='leadSearchInput'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid w-250px ps-14'
                      placeholder='Search'
                      value={searchTerm}
                      onChange={handleSearch}
                  />
              </div>
              {/* end::Search */}
            </div>
            {currentUser?.permissions?.Notification_Management?.write && (
            <div className="d-flex justify-content-end mb-3" data-kt-user-table-toolbar="base">
                <Link to ={`../add`} ><button type="button" className="btn btn-primary">
                <i className="ki-duotone ki-plus fs-2"></i>Create Tamplate</button></Link>
            </div>
            )}
            {/*<!--begin::Content container-->*/}
            <div className='row g-5 g-xl-8'>
                {templateData?.map((item: any) => {
                    return(
                        <div className='col-xl-4'>
                            <div
                                className='card bgi-no-repeat card-xl-stretch mb-5 mb-xl-8'
                                style={{
                                    backgroundPosition: 'right top',
                                    backgroundSize: '30% auto',
                                }}
                                >
                                {/* begin::Body */}
                                <div className='card-body d-flex flex-column justify-content-between align-items-start'>
                                    <p className='card-title fw-bold text-muted text-hover-primary fs-4'>
                                    {item?.value}
                                    </p>
                                    <div>
                                    <span className='text-dark-75 fw-semibold fs-5 m-0'>Notification header :  </span>
                                    <p className='fw-bold text-primary'>{item?.notificationHeader}</p>
                                    </div>
                                    <div>
                                    <span className='text-dark-75 fw-semibold fs-5 m-0'>Notification body: </span>
                                    <p
                                    
                                    dangerouslySetInnerHTML={{__html: item?.notificationContent.substring(0,400)}}
                                    ></p>
                                    </div>
                                    <div className="">
                                    {currentUser?.permissions?.Notification_Management?.write && (<Link to={`../edit/${item._id}`}><button type="button" className="btn btn-light btn-active-light-primary my-1" >Edit</button></Link>)}
                                    {currentUser?.permissions?.Notification_Management?.delete && (<button type="button" className="btn btn-light btn-active-primary my-1 m-2" onClick={() => handleDelete(item?._id)}>Delete</button>)}
                                    </div>
                                </div>
                                {/* end::Body */}
                                </div>
                        </div>
                    )
                })}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: "12px" }}>
                <Stack spacing={2}>
                    <Pagination count={dataCount} variant="outlined" shape="rounded" color="primary" onChange={(e,value)=>setPage(value)} />
                </Stack>
            </div>
            <Modal show={show} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Notification Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={()=>setShow(false)}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
            {/*<!--end::Content container-->*/}
        </div>
    )
}
export default NotificationTemplateList;